/* eslint-disable no-use-before-define */
<template>
  <div>
    <div class="list-item">
      <div class="item-panel">
        <div class="item-content">
            <p class="criteria-name">
                {{ $t('txt.order_id') }}:
            </p>
          <p class="criteria-value"> {{ item.id }} </p>
      </div>
        <div class="item-content">
            <p class="criteria-name">
                {{ $t('txt.shipping_address') }}:
            </p>
          <p class="criteria-value">
              {{ shippingAddress(item.shipping_address) }}
          </p>
      </div>
        <div class="item-content">
            <p class="criteria-name">
                Status:
            </p>
            <p class="criteria-value">
                {{ item.status.name.ro }}
            </p>
        </div>
        <div class="item-content" v-if="item.shipping_address && item.shipping_address.phone">
            <p class="criteria-name">
                {{ $t('txt.phone') }}:
            </p>
            <p class="criteria-value" >
              <a :href="`tel:${item.shipping_address.phone}`" class="criteria-link">
                {{ item.shipping_address.phone }}
              </a>
            </p>
        </div>
    </div>
         <b-button class="item-detail-button" v-b-modal.modal="`modal-${this.item.id}`"> {{ $t('txt.order_details'), }} </b-button>
    </div>
    <div>
      <b-modal :id="`modal-${this.item.id}`" class="item-modal" :title="$t('txt.order_details')" hide-footer>
          <div class="item-modal">
             <div class="item-modal-detail">
                  <p>
                      Status
                  </p>
                  <div class="select">
                      <select  v-model="selectedStatus" id="slct">
                          <option v-for="(status, key) in statuses" :value="status.id" :key="`A-${key}`"> {{status.name}} </option>
                      </select>
                  </div>
              </div>
              <div class="item-modal-detail" v-for="(input, key) in customer" :key="`B-${key}`">
                <span> {{input.labelName}} </span>
                <p v-if="!input.phone"> {{input.inputValue}} </p>
                <p v-else> <a :href="`tel: ${input.inputValue}`" class="item-modal-detail-link">{{input.inputValue}}</a> </p>
              </div>
              <div class="item-modal-detail item-modal-detail--product" v-for="(input, key) in products" :key="`C-${key}`">
                <span> {{input.labelName}} : {{ input.inputValue.length}}</span>
                <a v-b-toggle.product-list class="item-modal-detail-link"> {{ $t('txt.see_all') }} </a>
                <b-collapse id="product-list" class="product-list">
                  <p class="product" v-for="(product, key) in input.inputValue" :key="`prod-${key}`">
                    <span class="product__title" v-if="product.name"> {{product.name.ro}} </span>
                    <span class="product__multiply"> x </span>
                    <span class="product__qty" v-if="product.pivot"> {{product.pivot.qty}} </span>
                  </p>
                </b-collapse>
              </div>
              <div class="item-modal-detail" v-for="(input, key) in payment_method" :key="`D-${key}`">
                <span> {{input.labelName}} </span>
                <p> {{input.inputValue}} </p>
              </div>
              <div class="item-modal-detail" v-for="(input, key) in shipping_address" :key="`E-${key}`">
                <span> {{input.labelName}} </span>
                <p> {{input.inputValue}} </p>
              </div>
              <div class="item-modal-detail item-mentions">
                  <label>Observatii:</label>
                  <b-form-textarea  v-model="observations"></b-form-textarea>
                  <span class="form-error" v-if="observationsMessageError">{{observationsMessageError}}</span>
              </div>
              <div class="item-modal-detail item-modal-total" v-for="(input, key) in total" :key="`F-${key}`">
                <p class="item-total-value">
                    <span class="total-value-title"> {{input.labelName}} </span>
                     {{input.inputValue}} LEI
                 </p>
              </div>
              <div class="custom-button">
                  <button @click.prevent="saveOrder(`modal-${item.id}`)"> Salveaza </button>
                  <a :href="link" target="_blank" class="map-link map-link--item">Start Nav</a>
              </div>
          </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import OrdersService from '@/api-services/orders.service.js'
export default {
  name: 'ListItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    link: {
      type: String
    },
    data () {
      return {
        changedStatus: false
      }
    }
  },
  watch: {
    observations (newVal, oldVal) {
      if (newVal === '' && this.selectedStatus === 5) {
        this.observationsMessageError = this.$t('order.observations')
      } else {
        this.observationsMessageError = ''
      }
    },
    selectedStatus (newVal) {
      if (newVal !== 5) {
        this.observationsMessageError = ''
      }
    }
  },
  data () {
    return {
      selectedStatus: this.item.status.id,
      shipping_adress: this.shippingAddress(this.item.shipping_address),
      observations: (this.item.observations) ? this.item.observations : '',
      observationsMessageError: '',
      statuses: [
        {
          name: 'Livrata',
          id: 4
        },
        {
          name: 'Anulata',
          id: 5
        }
      ],
      status: [
        {
          inputName: 'status',
          labelName: 'Status',
          inputValue: (this.item && this.item.status) ? this.item.status.name.ro : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      customer: [
        {
          inputName: 'first_name',
          labelName: this.$t('txt.first_name'),
          inputValue: (this.item && this.item.customer) ? this.item.customer.first_name : null,
          icon: 'fas fa-sort-numeric-up'
        },
        {
          inputName: 'last_name',
          labelName: this.$t('txt.last_name'),
          inputValue: (this.item && this.item.customer) ? this.item.customer.last_name : null,
          icon: 'fas fa-sort-numeric-up'
        },
        {
          inputName: 'phone',
          labelName: this.$t('txt.phone'),
          inputValue: (this.item && this.item.shipping_address) ? this.item.shipping_address.phone : null,
          icon: 'fas fa-sort-numeric-up',
          phone: true
        }
      ],
      delivery_type: [
        {
          inputName: 'deliveryType',
          labelName: 'Delivery type',
          inputValue: (this.item && this.item.delivery_type) ? this.item.delivery_type.name.ro : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      payment_method: [
        {
          inputName: 'paymentMethod',
          labelName: this.$t('txt.payment_method'),
          inputValue: (this.item && this.item.payment_type) ? this.item.payment_type.name.ro : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      products: [
        {
          inputName: 'products',
          labelName: this.$t('txt.products'),
          inputValue: (this.item && this.item.products) ? this.item.products : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      shipping_address: [
        {
          inputName: 'address',
          labelName: this.$t('txt.address'),
          inputValue: (this.item && this.item.shipping_address) ? this.item.shipping_address.address : null,
          icon: 'fas fa-sort-numeric-up'
        },
        {
          inputName: 'city',
          labelName: 'Zona livrare',
          inputValue: (this.item && this.item.shipping_address && this.item.shipping_address.city) ? (this.item.shipping_address.city ? this.item.shipping_address.city.name : null) : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ],
      total: [
        {
          inputName: 'total',
          labelName: this.$t('txt.total'),
          inputValue: (this.item && this.item.total) ? this.item.total : null,
          icon: 'fas fa-sort-numeric-up'
        }
      ]
    }
  },
  methods: {
    shippingAddress (shippingAddressObject) {
      if (shippingAddressObject !== null && shippingAddressObject !== undefined) {
        return shippingAddressObject.address
      }
      return null
    },
    saveOrder (modalId) {
      if (this.selectedStatus === 5 && this.observations === '') {
        this.observationsMessageError = this.$t('order.observations')
        return false
      }
      this.$store.dispatch('showLoading')
      let formData = new FormData()
      formData.append('status_id', this.selectedStatus)
      formData.append('observation', this.observations)
      formData.append('_method', 'PUT')
      OrdersService.update(this.item.id, formData)
        .then((response) => {
          if (response.status === 200) {
            this.$bvModal.hide(modalId)
          }
          location.reload()
          this.$store.dispatch('hideLoading')
        })
        .catch((error) => {
          this.$store.dispatch('hideLoading')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="css" scoped>
    .item-modal-detail-link:hover {
        cursor:pointer;
    }
</style>
