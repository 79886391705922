<template>
  <div class="list">
    <div class="page-title">
      <h1> Comenzi </h1>
    </div>
    <div class="list-container">
      <ListItem v-for="item in items" :key="item.id" :item="item" :link="link"></ListItem>
    </div>
    <a :href="link" class="map-link">Start Navigation</a>
  </div>
</template>

<script>
import ListItem from '../../components/PageComponents/ListItem'
import DeliveryOrders from '@/api-services/orders.service'
import ShippingAddresses from '@/api-services/addresses.service'
export default {
  name: 'OrdersList',
  data () {
    return {
      items: null,
      error: null,
      addresses: []
    }
  },
  components: {
    ListItem
  },
  computed: {
    link () {
      return 'https://www.google.com/maps/dir/Current+Location/' + this.addresses.join('/')
    }
  },
  methods: {
    getDeliveryOrders () {
      DeliveryOrders.deliveryOrders()
        .then((response) => {
          if (response.data.data.length > 0) {
            if (this.items) {
              let newItems = response.data.data
              for (let i in newItems) {
                if (!this.items.find(order => parseInt(order.id) === parseInt(newItems[i].id))) {
                  this.items.push(newItems[i])
                }
              }
            } else {
              this.items = response.data.data
            }
          } else {
            this.results = null
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getShippingAddresses () {
      ShippingAddresses.getAddresses()
        .then((response) => {
          if (response.data.data.length) {
            this.addresses = response.data.data.map(shippingAddress => shippingAddress.lat + ',' + shippingAddress.lng)
          } else {
            this.addresses = []
          }
        })
        .catch((error) => {
          console.log(error, 'error')
        })
    }
  },
  mounted () {
    let vm = this
    setInterval(function () {
      vm.getDeliveryOrders()
      vm.getShippingAddresses()
    }, 25000)
  },
  async created () {
    await this.getDeliveryOrders()
    await this.getShippingAddresses()
    this.$store.dispatch('hideLoading')
  }
}
</script>

<style lang="css" scoped>
</style>
