import axios from 'axios'

const RESOURCE_NAME_DELIVERY = '/user-orders'
const RESOURCE_NAME = '/orders'

export default {
  deliveryOrders () {
    return axios.get(`${RESOURCE_NAME_DELIVERY}`)
  },
  update (id, data) {
    return axios.post(`${RESOURCE_NAME}/${id}`, data)
  }
}
